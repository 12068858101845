<template>
  <div class="add-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/guest/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">嘉宾管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加嘉宾</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="addForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <upload :url="addForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="嘉宾词条" prop="tags">
          <el-select v-model="addForm.tags" multiple filterable allow-create default-first-option placeholder="请选择嘉宾词条"></el-select>
        </el-form-item>
        <el-form-item label="嘉宾介绍" prop="desc">
          <QuillBar v-model="addForm.desc" :contenttext="addForm.desc" @inputChange="descChange"></QuillBar>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="addForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('addRef')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
import QuillBar from '../../../../common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      addForm: {
        mId: this.$route.params.mId,
        sort: 0,
        name: '',
        avatar: '',
        tags: [],
        desc: '',
        is_auth: 2
      },
      addRules: {
        name: [
          { required: true, message: '请填写嘉宾姓名', trigger: 'blur' },
          { min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur' }
        ]
      },
      quill: null,
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow',
        modules: {}
      }
    }
  },
  methods: {
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    // 添加表单
    submitForm (formname) {
      this.$refs.addRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-guest/0', this.addForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.addRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/guest/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 嘉宾介绍
    descChange (value) {
      this.addForm.desc = value
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/guest/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    }
  }
}
</script>

<style scoped>
.add-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
